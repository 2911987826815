import { Link as RainbowStyledLink } from '@otovo/rainbow';
import Link from 'next/link';
import { Rainbow$Props } from '@otovo/shared/types/rainbow';

import { getLinkTag } from './getLinkTag';

type Props = {
  as?: any;
  href?: string;
  target?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: any;
  rel?: string;
  locale?: string;
} & Rainbow$Props;

const RainbowLink = ({
  as,
  href,
  children,
  target,
  onClick,
  icon,
  rel,
  locale,
  ...rest
}: Props) => {
  const linkTag = getLinkTag({ as, href, target });

  // This covers three cases:
  // 1. External links: Full links to other domains (or subdomains). Will trigger full page refresh.
  // 2. Buttons styled as links
  // 3. mailto and tel links
  if (linkTag) {
    return (
      <RainbowStyledLink
        as={linkTag}
        href={href}
        target={target}
        onClick={onClick}
        icon={icon}
        rel={rel}
        locale={locale}
        {...rest}
      >
        {children}
      </RainbowStyledLink>
    );
  }

  // Relative links (e.g. storefront -> storefront) should use next/link
  return (
    <Link passHref href={href} scroll={false} locale={locale} legacyBehavior>
      <RainbowStyledLink
        as="a"
        onClick={onClick}
        icon={icon}
        rel={rel}
        {...rest}
      >
        {children}
      </RainbowStyledLink>
    </Link>
  );
};

RainbowLink.displayName = 'RainbowLink';
export default RainbowLink;
