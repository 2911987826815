import log from 'loglevel';
import { useRouter } from 'next/router';
import NextHead from 'next/head';

import { useGlobalConfig } from '../../configContext';

import { getCanonicalUrl, getHref } from './urlGenerator';
import { HeadProps } from './flowTypes';
import { getHeadBaseUrl } from './headContext';

function getImageUrl(baseUrl: string, imageUri: string) {
  const absoluteUrlRegexp = /^http/;
  if (!imageUri) {
    return null;
  }
  if (absoluteUrlRegexp.test(imageUri)) {
    return imageUri;
  }

  return `${baseUrl}${imageUri}`;
}

const getTruncatedDesciption = (description: string) => {
  return description.length > 300
    ? `${description.slice(0, 300)}...`
    : description;
};

const Head = ({
  children,
  description,
  image = '',
  imageHeight,
  imageWidth,
  noindex,
  preload,
  title,
  ...rest
}: HeadProps) => {
  const { asPath, locale } = useRouter();
  const config = useGlobalConfig();
  const isMultiLocaleBu = config.BU_CONFIG.supported_locales.length > 1;

  const baseUrl = getHeadBaseUrl() || '';
  const href = getHref(asPath, baseUrl);
  const canonicalUrl = getCanonicalUrl(
    asPath,
    baseUrl,
    isMultiLocaleBu ? locale : null,
  );
  const imageUrl = getImageUrl(baseUrl, image);

  // TODO: Make custom eslint plugin for these warnings
  if (title && title.length > 70) {
    log.warn(
      `[SEO Warning] Title at ${href} field should not exceed 70 characters, and will be truncated in search results`,
    );
  }
  if (description && description.length > 160) {
    log.warn(
      `[SEO Warning] Description at ${href} field should not exceed 160 characters, and will be truncated in search results`,
    );
  }

  // Truncate the description to avoid too long texts being set in head
  const truncatedDesc = description ? getTruncatedDesciption(description) : '';

  return (
    <NextHead {...rest}>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={config.BU_CONFIG.locale_territory} />
      <meta property="og:site_name" content="Otovo" />
      <meta property="fb:app_id" content={config.FACEBOOK_APP_ID} />
      <meta name="twitter:site" content="@otovosolar" />
      {title && [
        <title key="title">{title}</title>,
        <meta key="og:title" property="og:title" content={title} />,
        <meta key="twitter:title" property="twitter:title" content={title} />,
      ]}
      {description && (
        <meta key="description" name="description" content={truncatedDesc} />
      )}
      {description && (
        <meta
          key="og:description"
          property="og:description"
          content={truncatedDesc}
        />
      )}
      {imageUrl && (
        <meta key={imageUrl} property="og:image" content={imageUrl} />
      )}
      {imageHeight && (
        <meta property="og:image:width" content={String(imageHeight)} />
      )}
      {imageWidth && (
        <meta property="og:image:height" content={String(imageWidth)} />
      )}
      {href && <meta property="og:url" content={href} />}
      {href && <meta property="twitter:url" content={href} />}
      {!!preload && <link rel="preload" href={preload} as="image" />}
      {!!canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {!!noindex && <meta name="robots" content="noindex" />}
      <link
        href="https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap"
        rel="stylesheet"
      />
      {children}
    </NextHead>
  );
};

Head.defaultProps = {
  children: undefined,
  description: undefined,
  title: undefined,
  image: undefined,
  imageHeight: undefined,
  imageWidth: undefined,
  preload: undefined,
};

export default Head;
