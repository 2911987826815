import { FormattedMessage as T } from 'react-intl';

import { Rainbow$Props } from '@otovo/shared/types/rainbow';
import RainbowLink from '../RainbowLink/RainbowLink';
import { openChat } from '../../apps/Chat/utils';

import m from './messages';

type Props = { children: React.ReactNode } & Rainbow$Props;

const OpenChat = ({ children, ...rest }: Props) => (
  <RainbowLink as="button" {...rest} onClick={() => openChat()}>
    {children || <T {...m.defaultChatLinkLabel} />}
  </RainbowLink>
);

OpenChat.defaultProps = {
  children: null,
};

export default OpenChat;
